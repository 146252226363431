.navbar ul {
    list-style-type: none;
    display: flex;
    min-width: 100vw;
    margin: 0;
    padding: 0;
    background-color: #1DB954;
}
.navbar ul li {
    display: inline;
    padding: 0 1vw 0 1vw;
    transition: transform .5s ease;
    border-radius: 5px;
}

.navbar ul li a {
    line-height: 1vh;
    text-decoration: none;
    color: black;
}


.logo {
    line-height: 1vh;
}

.navbar ul li:hover {
    background-color: #1a9e48;
}