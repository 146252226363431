.home {
    width: 100vw;
    margin: auto;
    border: 2px solid;
    padding: 1vw;
    text-align: center;
    height: 100vh;
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.beginner-button {
    color: black;
    background-color: #1DB954;
    border: none;
    text-align: center;
    padding: 1em;
    border-radius: 10px;
    margin: auto 0 auto 0;
    font-weight: 800;
    font-size: 18px;
    transition: transform .5s ease;
    margin-top: 10px;
}

.beginner-button:hover {
    transform: scale(1.05);
}

.exercises {
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
    justify-content: center;
}

.header {
    text-align: center;
    font-size: 48px;
}

.title {
    color: white;
    font-size: 48px;
    font-weight: 700;
}

.imgonnakms {
    overflow: hidden;
}

